import { Component } from "react";
import { Modal } from 'react-bootstrap'
import { AllowOnlyAlphabatsWithBrackets } from "../Validation";

class AddTimeSlot extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fieldValue: this.props.selectedTimeSlot != null ? this.props.selectedTimeSlot.slotType : '',
      isfieldError: false,
      fieldValue1: this.props.selectedTimeSlot != null ? this.props.selectedTimeSlot.startRange : '',
      isfieldError1: false,
      fieldValue2: this.props.selectedTimeSlot != null ? this.props.selectedTimeSlot.endRange : '',
      isfieldError2: false,

    }
  }

  onfieldchange = (e) => {
    this.setState({ fieldValue: e.target.value.replace(/ {2,}/g, ' ') })
    if (e.target.value.length >= 1)
      this.setState({ isfieldError: false })
  }

  onfieldchange1 = (e) => {
    this.setState({ fieldValue1: e.target.value })
    if (e.target.value.length > 0)
      this.setState({ isfieldError1: false })
  }

  onfieldchange2 = (e) => {
    this.setState({ fieldValue2: e.target.value })
    if (e.target.value.length > 0)
      this.setState({ isfieldError2: false })
  }

  onYesClick = (e) => {
    if (this.state.fieldValue.length >= 1 && this.state.fieldValue1 >= 0 && this.state.fieldValue2 >= 0)
      this.props.onConfirm({
        SlotType: this.state.fieldValue,
        StartRange: this.state.fieldValue1,
        EndRange: this.state.fieldValue2
      })
    else {
      if (this.state.fieldValue.length < 1)
        this.setState({ isfieldError: true });
      if (this.state.fieldValue1 == "")
        this.setState({ isfieldError1: true });
      if (this.state.fieldValue2 == "")
        this.setState({ isfieldError2: true });

    }

  }

  checkOnBlur = () => {
    if (this.state.fieldValue.length >= 1)
      this.setState({ isfieldError: false })
    else
      this.setState({ isfieldError: true })
  }

  checkOnBlur1 = () => {
    if (this.state.fieldValue1.toString().length >= 1)
      this.setState({ isfieldError1: false })
    else
      this.setState({ isfieldError1: true })
  }

  checkOnBlur2 = () => {
    if (this.state.fieldValue2.toString().length >= 1)
      this.setState({ isfieldError2: false })
    else
      this.setState({ isfieldError2: true })
  }

  OnInputNumber =(e) =>{
    if (e.target.value.length > e.target.maxLength) 
    e.target.value = e.target.value.slice(0, e.target.maxLength);
  }

  render() {
    return (
      <div className="modal show" style={{ display: 'block', position: 'initial' }}>
        <Modal show={this.props.isPopupOpen} onHide={this.props.onCloseModel} >
          <Modal.Header className="modal-footer justify-content-center">
            <h5 id="exampleModalLabel">{this.props.isEditClick ? "Edit" : "Add"} Time Slots </h5>
          </Modal.Header>
          <Modal.Body>
            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 > Slot Type </h6>
              </div>
            </div>

            <div className="feedback-wrapper">
              <div className="mt-3">
                <textarea onPaste={e => !AllowOnlyAlphabatsWithBrackets(e) && e.preventDefault()} onKeyDown={e => !AllowOnlyAlphabatsWithBrackets(e) && e.preventDefault()} onChange={this.onfieldchange} value={this.state.fieldValue} className={this.state.isfieldError ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter Time Slot"
                  id="exampleFormControlTextarea1" name="fieldValue" rows="2" onBlur={() => this.checkOnBlur()}></textarea>
              </div>
            </div>
            {
              this.state.isfieldError ?
                <div style={{ color: 'red' }}> Please provide atleast 1 characters.</div> : ''
            }
            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 > Start Range </h6>
              </div>
            </div>

            <div className="feedback-wrapper">
              <div className="mt-3">
                <input type="number" pattern="\d*" onInput={this.OnInputNumber} maxLength={2} onChange={this.onfieldchange1} value={this.state.fieldValue1} className={this.state.isfieldError1 ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter Start Range (in hours) upto 2 numbers"
                  id="exampleFormControlTextarea2" name="fieldValue1" onBlur={() => this.checkOnBlur1()}></input>
              </div>
            </div>
            {
              this.state.isfieldError1 ?
                <div style={{ color: 'red' }}> Please provide atleast 1 characters.</div> : ''}


            <div className="col-md-12 w-100">
              <div className="mb-3">
                <h6 > End Range </h6>
              </div>
            </div>

            <div className="feedback-wrapper">
              <div className="mt-3">
                <input type="number" pattern="\d*" onInput={this.OnInputNumber} maxLength={2} onChange={this.onfieldchange2} value={this.state.fieldValue2} className={this.state.isfieldError2 ? "form-control cancelBox is-invalid" : "form-control cancelBox"} placeholder="Enter End Range (in hours) upto 2 numbers"
                  id="exampleFormControlTextarea3" name="fieldValue" onBlur={() => this.checkOnBlur2()}></input>
              </div>
            </div>
            {
              this.state.isfieldError2 ?
                <div style={{ color: 'red' }}> Please provide atleast 1 characters.</div> : ''
            }
            {this.props.errorMessage != "" ? <h6 className="text-center err-msg-used">{this.props.errorMessage}</h6> : ""}

            <div className="d-flex justify-content-center mt-4">
              <button type="button" onClick={this.props.onCloseModel} className="btn outline-button btn-small" data-bs-dismiss="modal">Cancel</button>
              <button type="button" onClick={(e) => this.onYesClick(e)} className="btn fill-button btn-small">{this.props.isEditClick ? "Update" : "Save"}</button>
            </div>
          </Modal.Body>


        </Modal>

      </div>
    );
  }

}

export default AddTimeSlot;