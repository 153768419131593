import { Component } from "react";
import { connect } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import LunchOff from '../../Images/lunch-off.jpg'
import LunchOn from '../../Images/lunch-on.jpg'
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";
class EditPage4 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLunch: this.props.AdminEditBooking.bookingDetails.lunch,
      imagePath: LunchOff
    };

  }

  PreviousButtonClick = () => {

    this.props.adminCurrentPage(3);
    this.props.sendData(3)
  }

  changeImage = (e) => {
    if (e.target.id == "yes") {
      this.setState({ imagePath: LunchOn, isLunch: true });
      let bookingDetails = this.props.AdminEditBooking.bookingDetails;
      bookingDetails.lunch = true;
      this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
    }
    else {
      this.setState({ imagePath: LunchOff, isLunch: false });
      let bookingDetails = this.props.AdminEditBooking.bookingDetails;
      bookingDetails.lunch = false;
      this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
    }
  }



  NextButtonClick = () => {

    this.props.adminCurrentPage(5);
    this.props.sendData(5)
  }

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }


  render() {
    return <fieldset className="wizard-fieldset show">
      <div className="col-md-12 right-section text-center">
        <h6 className="text-center pb-1"> {this.getTranslations("User_BookingLunchPage_Heading")} </h6>
        <div className="col-md-6 mt-4 mb-2 mx-auto stv-radio-buttons-wrapper ">
          <input defaultChecked={this.state.isLunch} type="radio" className="stv-radio-button en-nn" name="isLunch" value="1" id="yes" />
          <label id="yes" htmlFor="yes" onClick={this.changeImage} >YES</label>
          <input defaultChecked={!this.state.isLunch} type="radio" className="stv-radio-button en-nn" name="isLunch" value="3" id="no" />
          <label id="no" htmlFor="no" onClick={this.changeImage}>NO</label>
        </div>
        {!this.state.isLunch ? <img id="myImage" src={LunchOff} width="200" className="mb-2" />
          : <img id="myImage" src={LunchOn} width="200" className="mb-2" />}

        <div className="col-md-6 mt-4 mb-2 mx-auto">

          <div className="wizard-form-error"></div>
        </div>

      </div>
    
      <div className="text-center LunchNote"> {this.getTranslations("User_BookingLunchPage_Note")} </div>

      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
          <button onClick={this.PreviousButtonClick} className="form-wizard-previous-btn float-left">Previous</button>
          <button onClick={this.NextButtonClick} className="form-wizard-next-btn float-right">Continue <i className="fa fa-angle-right"></i></button>
        </div>
      </div>

    </fieldset>


  }
}

const mapStateToProps = (state) => ({ 
  Translations: state.Translations,
  AdminAuthentication: state.AdminAuthentication,
  AdminEditBooking: state.AdminEditBooking,
  AdminCurrentPage: state.AdminCurrentPage,
  AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, { loginUserInfoAdmin, adminCurrentPage, saveEditBookingInfo })(EditPage4);
