import { faArchive, faCalendar, faCaretDown, faCaretUp, faHistory, faHome, faInfo, faLanguage, faList, faListAlt, faMap, faMapMarker, faTable, faTimes, faUser, faUserAlt, faUsers } from "@fortawesome/fontawesome-free-solid";
import { faGear, faGripVertical, faLocation, faQ, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import $ from 'jquery';
import { Navigate, Route, Routes } from "react-router";
import AdminLogo from '../../Images/fluid-admin-logo.png'
import '../../Css/dashboardStyle.css'
import DashboardContent from "./Graphs/DashboardContent";
import MasterConfigurations from "./Configurations/MasterConfiguration";
import MasterLocation from "./Configurations/MasterLocations";
import MasterCustomerTypes from "./Configurations/MasterCustomerTypes";
import MasteropportunitySizes from "./Configurations/MasterOpprtunitySizes";
import MasterSlots from "./Configurations/MasterSlot";
import MasterRegions from "./Configurations/MasterRegions";
import MasterSubRegions from "./Configurations/MasterSubregions";
import MasterProductCategories from "./Configurations/MasterProductCategories";
import MasterVerticals from "./Configurations/MasterVerticals";
import MasterFeedbackQuestions from "./Configurations/MasterFeedbackQuestions";
import { connect } from "react-redux";
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { leftMenuSelection } from '../../Redux/Actions/AdminLeftMenuAction'
import ErrorLogs from "./Configurations/ErrorLogs";
import AuditLogs from "./Configurations/AuditLogs";
import MasterVisitTypes from "./Configurations/MasterVisitTypes";
import MasterTranslations from "./Configurations/MasterTranslations";
import MasterUsers from "./Configurations/MasterUsers";
import AdminBookingListForEdit from "../AdminEditBooking/AdminBookingListForEdit";

class AdminDashboard extends Component {

    constructor(props) {
        super(props);
        if (window.location.pathname == "/dashboard") {
            this.props.leftMenuSelection({ selection: false, selectionLogs: false, selectedSubMenu: 0 });
        }
        this.state = {
            selectedMenu: 0,
            isDropdownOpen: false,
            isDropdownOpenForLogs: false
        };
    }

    onDropdownOpen = (e) => {
        this.props.leftMenuSelection({ selection: !this.state.isDropdownOpen, selectionLogs: false, selectedSubMenu: this.props.AdminLeftMenu.selectedSubMenu })
        this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
    }

    navBarToggle = (e) => {
        $(".custom-nav").toggleClass("opensidebar");
    }

    onDropdownOpenForLogs = (e) => {
        this.props.leftMenuSelection({ selectionLogs: !this.state.isDropdownOpenForLogs, selection: false, selectedSubMenu: this.props.AdminLeftMenu.selectedSubMenu })
        this.setState({ isDropdownOpenForLogs: !this.state.isDropdownOpenForLogs });
    }

    onMenuSelection = (e) => {
        this.props.leftMenuSelection({ selection: this.props.AdminLeftMenu.selection, selectionLogs: this.props.AdminLeftMenu.selectionLogs, selectedSubMenu: e.target.id })
    }

    render() {
        if (this.props.AdminAuthentication.isLogin) {
            return <>
                <div id="wrapperdashboard">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <div className="container-fluid">
                            <button className="navbar-toggler hide-desk" onClick={this.navBarToggle} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                        </div>
                    </nav>
                    <div id="sidebar-dashboard" className="custom-nav">
                        <h2>
                            <img src={AdminLogo} className="img-fluid" alt="" />
                        </h2>



                        <ul className="nav-link">
                            <li> <a id="0" onClick={this.onMenuSelection} href="/dashboard" className={this.props.AdminLeftMenu.selectedSubMenu == "0" ? "active" : ""}> <FontAwesomeIcon icon={faHome} /> Home </a> </li>
                            <li> <a id="1" onClick={this.onDropdownOpen}> <FontAwesomeIcon icon={faTable} onClick={this.onDropdownOpen} /> Master Data <FontAwesomeIcon className="float-end" icon={!this.state.isDropdownOpen ? faCaretDown : faCaretUp} /> </a>
                                <ul className={this.props.AdminLeftMenu.selection ? "" : "d-none"}>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/visittypes" id="15" className={this.props.AdminLeftMenu.selectedSubMenu == "15" ? "active" : ""}> <FontAwesomeIcon icon={faUserGroup} /> Visit Types </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/locations" id="2" className={this.props.AdminLeftMenu.selectedSubMenu == "2" ? "active" : ""}><FontAwesomeIcon icon={faLocation} /> Location </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/customertypes" id="3" className={this.props.AdminLeftMenu.selectedSubMenu == "3" ? "active" : ""}> <FontAwesomeIcon icon={faUser} /> Customer Type </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/oppotunitysizes" id="4" className={this.props.AdminLeftMenu.selectedSubMenu == "4" ? "active" : ""}> <FontAwesomeIcon icon={faUserAlt} /> Opportunity Size </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/timeslots" id="5" className={this.props.AdminLeftMenu.selectedSubMenu == "5" ? "active" : ""}> <FontAwesomeIcon icon={faCalendar} /> Time Slots </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/regions" id="6" className={this.props.AdminLeftMenu.selectedSubMenu == "6" ? "active" : ""}> <FontAwesomeIcon icon={faMap} /> Region </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/subregions" id="7" className={this.props.AdminLeftMenu.selectedSubMenu == "7" ? "active" : ""}> <FontAwesomeIcon icon={faMapMarker} /> SubRegions </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/productcategories" id="8" className={this.props.AdminLeftMenu.selectedSubMenu == "8" ? "active" : ""}> <FontAwesomeIcon icon={faList} /> Product Categories </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/verticals" id="9" className={this.props.AdminLeftMenu.selectedSubMenu == "9" ? "active" : ""}> <FontAwesomeIcon icon={faGripVertical} /> Verticals </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/feedbackquestions" id="10" className={this.props.AdminLeftMenu.selectedSubMenu == "10" ? "active" : ""}> <FontAwesomeIcon icon={faQ} /> Feedback Questions </a> </li>
                                    <li> <a onClick={this.onMenuSelection} href="/dashboard/translations" id="16" className={this.props.AdminLeftMenu.selectedSubMenu == "16" ? "active" : ""}> <FontAwesomeIcon icon={faLanguage} /> Translations </a> </li>
                                    {
                                        this.props.AdminAuthentication.role == "superadmin" ?
                                            <>
                                                <li> <a onClick={this.onMenuSelection} href="/dashboard/masterusers" id="17" className={this.props.AdminLeftMenu.selectedSubMenu == "17" ? "active" : ""}> <FontAwesomeIcon icon={faUsers} /> Users </a> </li>
                                                <li> <a onClick={this.onMenuSelection} href="/dashboard/bookings" id="18" className={this.props.AdminLeftMenu.selectedSubMenu == "18" ? "active" : ""}> <FontAwesomeIcon icon={faListAlt} /> Bookings </a> </li>
                                            </>
                                            : ""
                                    }

                                </ul>
                            </li>
                            <li> <a id="11" className={this.props.AdminLeftMenu.selectedSubMenu == "11" ? "active" : ""} onClick={this.onMenuSelection} href="/dashboard/configurations"> <FontAwesomeIcon icon={faGear} /> Configurations </a> </li>
                            <li> <a id="12" onClick={this.onDropdownOpenForLogs}> <FontAwesomeIcon icon={faInfo} onClick={this.onDropdownOpen} /> Logs <FontAwesomeIcon className="float-end" icon={!this.state.isDropdownOpenForLogs ? faCaretDown : faCaretUp} /> </a>
                                <ul className={this.props.AdminLeftMenu.selectionLogs ? "" : "d-none"}>
                                    <li> <a id="13" className={this.props.AdminLeftMenu.selectedSubMenu == "13" ? "active" : ""} onClick={this.onMenuSelection} href="/dashboard/errorlogs"> <FontAwesomeIcon icon={faArchive} /> Error Logs </a> </li>
                                    <li> <a id="14" className={this.props.AdminLeftMenu.selectedSubMenu == "14" ? "active" : ""} onClick={this.onMenuSelection} href="/dashboard/auditlogs"> <FontAwesomeIcon icon={faHistory} /> Audit Logs </a> </li>
                                </ul>
                            </li>

                        </ul>

                        <a onClick={this.navBarToggle} className="closesidebar for-mobile"> <i className='fas fa-times'><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></i> </a>




                    </div>

                    <div className="content-area-dashboard">

                        <Routes>
                            <Route path="/" element={<DashboardContent />} />
                            <Route path="/locations" element={<MasterLocation />} />
                            <Route path="/customertypes" element={<MasterCustomerTypes />} />
                            <Route path="/oppotunitysizes" element={<MasteropportunitySizes />} />
                            <Route path="/timeslots" element={<MasterSlots />} />
                            <Route path="/regions" element={<MasterRegions />} />
                            <Route path="/subregions" element={<MasterSubRegions />} />
                            <Route path="/productcategories" element={<MasterProductCategories />} />
                            <Route path="/verticals" element={<MasterVerticals />} />
                            <Route path="/feedbackquestions" element={<MasterFeedbackQuestions />} />
                            <Route path="/configurations" element={<MasterConfigurations />} />
                            <Route path="/errorlogs" element={<ErrorLogs />} />
                            <Route path="/auditlogs" element={<AuditLogs />} />
                            <Route path="/visittypes" element={<MasterVisitTypes />} />
                            <Route path="/translations" element={<MasterTranslations />} />
                            {
                                this.props.AdminAuthentication.role == "superadmin" ? 
                                <>
                                <Route path="/masterusers" element={<MasterUsers />} /> 
                                <Route path="/bookings" element={<AdminBookingListForEdit />} /> 
                                </>
                                : ""
                            }

                        </Routes>

                    </div>

                </div>

                <footer className="text-center">
                Copyright © {new Date().getFullYear()} HCL Technologies Limited
                </footer>

            </>
        }
        else {
            return <Navigate replace to="/admin" />;
        }

    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
    AdminLeftMenu: state.AdminLeftMenu
})

export default connect(mapStateToProps, { loginUserInfoAdmin, leftMenuSelection })(AdminDashboard);