import { Component } from "react";
import { Navigate } from "react-router-dom";
import API from "../../Api";
import withRouter from "../WithRouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faEnvelope, faUser, faUserAlt, faUsers } from "@fortawesome/fontawesome-free-solid";
import { faHamburger, faLocation, faLocationPin, faSquarePollVertical, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { loginUserInfoAdmin } from '../../Redux/Actions/AdminAuthenticationActions'
import { connect } from "react-redux";
import QRCode from "react-qr-code";


class BookingDetailsPrint extends Component {

    constructor(props) {
        super(props);
        this.state = {
            bookingDetail: [],
            customers: [],
            products: [],
            isLoading: true,
            isBookieFeedbackDone: false,
            isAllCustomersFeedbackDone: false,
            displayCustomerText: "",
            redirect: '',
            summary: [],
            qrData: ''
        };
    }

    componentDidUpdate() {
        window.print();
        setTimeout(function () { window.close(); }, 100);
    }

    async componentDidMount() {
        await new API().getBookingDetails(this.props.params.id).then(result => {
            this.setState({
                qrData: result.data.qrData,
                bookingDetail: result.data.bookingDetail,
                customers: result.data.customers,
                summary: result.data.summaries,
                products: result.data.productsDemostrated,
                isLoading: false,
                isBookieFeedbackDone: result.data.isBookieFeedbackDone,
                isAllCustomersFeedbackDone: result.data.isAllCustomersFeedbackDone,
                displayCustomerText: result.data.bookingDetail.visitingPartySize > 1 ? " Customers + " : " Customer + "
            });
        });

    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        if (this.props.AdminAuthentication.isLogin) {
            return <>

                <div id="wrapper">

                    <div className="content-area-adminPrint">
                        <section className="w-100 booking-area">
                            <div >

                                <div style={{ height: "auto", margin: "0 auto", maxWidth: 300, width: "100%" }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={this.state.qrData}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>

                                <div className="row no-gutters mt-4">
                                    <h6 className="pb-2">Bookings details</h6>
                                </div>

                                <div className="row pb-2">
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>
                                                    <tr>
                                                        <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUserAlt}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.customerName} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.bookieName} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUserGroup}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.visitType} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-envelope"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.bookieEmail} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-envelope"><FontAwesomeIcon icon={faLocation}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.location} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-clock"><FontAwesomeIcon icon={faClock}></FontAwesomeIcon></i> <span> {moment.tz(moment.utc(this.state.bookingDetail.bookingDate),this.state.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  -  {moment.tz(moment.utc(this.state.bookingDetail.bookingEndDate),this.state.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-users"><FontAwesomeIcon icon={faUsers}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.participantsSize + this.state.bookingDetail.visitingPartySize} Guests ({this.state.bookingDetail.visitingPartySize} {this.state.displayCustomerText} {this.state.bookingDetail.participantsSize} HCL) </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-hamburger"><FontAwesomeIcon icon={faHamburger}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.lunchRequired ? "Yes" : "No"} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-hamburger"><FontAwesomeIcon icon={faSquarePollVertical}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.vertical} </span> </td>
                                                    </tr>
                                                    <tr>
                                                        <td> <i className="fa fa-hamburger"><FontAwesomeIcon icon={faLocationPin}></FontAwesomeIcon></i> <span> {this.state.bookingDetail.regionName} - {this.state.bookingDetail.subRegion} </span> </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>


                                    </div>
                                </div>

                                <div className="row no-gutters">
                                    <h6 className="pb-2">Visiting delegation</h6>
                                </div>

                                <div className="row pb-2">
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>

                                                    {this.state.customers.map((item, i) => {
                                                        return [
                                                            <tr key={item.id + 'customers'}>
                                                                <td> <i className="fa fa-user"><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></i>
                                                                    <span> {item.customerName} ( {item.customerRole} ) - {item.customerEmail}</span> </td>
                                                            </tr>
                                                        ]

                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="row no-gutters">
                                    <h6 className="pb-2">Product categories</h6>
                                </div>

                                <div className="row">
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>
                                                    {this.state.products.map((item, i) => {
                                                        return [
                                                            <tr key={item.id + 'products'}>
                                                                <td>  <span> {item} </span> </td>
                                                            </tr>]

                                                    })}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>

                                {this.state.bookingDetail.isConverted || this.state.bookingDetail.convertedComments != null ?
                                    <div className="row no-gutters mt-2">
                                        <h6 className="pb-2">Strategic Summary</h6>
                                    </div> : ""}

                                <div className="row">

                                    <div> {this.state.bookingDetail.isConverted ? "Booking Converted to DEAL" : ""}</div>
                                    <div> {(this.state.bookingDetail.convertedComments == null || this.state.bookingDetail.convertedComments == '') ? "" : "Business summary - " + this.state.bookingDetail.convertedComments}    </div>
                                    <div className="loop-table-main">
                                        <div className="loop-table-div w-100 mx-0">
                                            <table className="table table-bordered cnf-book-table">
                                                <tbody>
                                                    {this.state.summary.map((item, i) => {
                                                        return [
                                                            <tr key={item.id + 'summary'}>
                                                                <td>  <span> {item.name} </span>  -  <span className="date-bold"> {item.summary} </span> </td>
                                                            </tr>]

                                                    })}
                                                </tbody>
                                            </table>

                                        </div>


                                    </div>
                                </div>
                            </div>

                        </section>
                    </div>

                </div>
                <footer className="text-center">
                Copyright © {new Date().getFullYear()} HCL Technologies Limited
                </footer>

            </>
        }
        else {
            return <Navigate replace to="/admin" />;
        }
    }
}

const mapStateToProps = (state) => ({
    AdminAuthentication: state.AdminAuthentication,
})

export default connect(mapStateToProps, { loginUserInfoAdmin })(withRouter(BookingDetailsPrint));