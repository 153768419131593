import { Component } from "react";
import API from "../../Api";
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/fontawesome-free-solid";
import ReactFormInputValidation from "react-form-input-validation";
import moment from "moment";
import 'moment-timezone'
import axios from "axios";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";


class ConfirmBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: this.props.selectedDetails.id,
            displayCustomerText: this.props.selectedDetails.visitingPartySize > 1 ? " Customers + " : " Customer + ",
            selectedDetails: this.props.selectedDetails,
            //regions: this.props.regions,           
            isDateValid: true,
            lunchRequired: this.props.lunchRequired,
            fields: {
                selectedDate: this.selectTimeZoneDate(this.props.selectedDate, this.props.selectedDetails.timezone),
                selectedEndDate: this.selectTimeZoneDate(this.props.selectedEndDate, this.props.selectedDetails.timezone),
                // selectedRegion: '',
                // vertical: ''
            },
            IsSelectedDateChange: false,
            IsLunchRequiredChange: false,
            errors: {},
            isButtonDisabled: false
        }

        this.form = new ReactFormInputValidation(this);
        this.form.useRules({
            //selectedRegion: "required",
            //vertical: "required",
            selectedDate: "required"
        });

        this.form.onformsubmit = (fields) => {
            if (Object.keys(this.state.errors).length === 0 && this.state.isDateValid) {
                this.setState({ isButtonDisabled: true });
                this.SaveData();
            }
        }
        let message = ReactFormInputValidation.getMessages('en');
        message.required = 'This field is required.'
        ReactFormInputValidation.setMessages('en', message);
    }

    selectTimeZoneDate(date, timezone) {
        const dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DD HH:mm")
        const localZone = moment(dateWithoutZone).format("Z")
        const dateWithLocalZone = [dateWithoutZone, localZone].join("")
        return new Date(dateWithLocalZone);
    }

    SaveData = () => {
        let bookingInfo = {
            bookingId: this.state.selectedId,
            // vertical: this.state.fields.vertical,
            // regionId: this.state.fields.selectedRegion,
            selectedDate: moment(this.state.fields.selectedDate),
            lunchRequired: this.state.lunchRequired == "Yes" ? true : false
        };

        new API().confirmAndUpdateBookings(bookingInfo).then(result => {
            if (result.data.sendEmail) {
                this.sendEmailtoBookie(result);
                this.sendEmailToAdmin(result);
            }
            if (result.data.sendInvite) {
                this.props.selectedName == "confirm" ? this.SendMeetingInvite(result) : this.SendUpdatedMeetingInvite(result);
            }
            this.props.onCloseModelAfterSuccess();
        });

    }

    sendEmailtoBookie(result) {    

        const headers = {
            'Authorization': `Bearer ${result.data.token}`,
            'Content-Type': 'application/json'

        };

        let bodyMsg =
            `<html><head></head><body><br />Dear ${result.data.bookingDetails.bookingDetail.bookieName},<br /> <br /> Your booking has been confirmed.<br />
              <span style=color:blue;>Booking Details</span> :
              <b>Booking No -</b> ${result.data.id}
              <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
              <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
              <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}         
              <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate), result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
              <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate), result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
              <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
              <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
              <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
              <span style=color:blue;>Products categories - </span> ${result.data.bookingDetails.productsDemostrated.map((product) => '<br />' + product)}<br />
              <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}
              <br />
              <b>Thanks</b>
              FLUID Experience Centre Team
             </body>
            </html>`
        bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
        let sendmessageBody = `{
            "message": {
            "subject": "${result.data.id} - Booking Confirmed",
            "body": {
            "contentType": "Html",
            "content": "${bodyMsg}"
            },
            "toRecipients": [
            {
            "emailAddress": {
            "address": "${result.data.bookingDetails.bookingDetail.bookieEmail}"
            }
            }
            ]
            },
            "saveToSentItems": "true"
            }`
        const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
        axios.post(URL, sendmessageBody, { headers })
            .then(response => {

            }).catch(error => {
                new API().logServiceErrorToDatabase(error);
                console.log("error while sending mail", error);
            });
    }

    sendEmailToAdmin(result) {

        let ccRecipients = result.data.adminEmails.map((adminMail) => `{
            "emailAddress": {
              "address": "${adminMail.trim()}"
            }
          }`)

        let addmore = result.data.adminEmails.length > 0 ? `
           "toRecipients": [
            ${ccRecipients}
           ]`: '';

        const headers = {
            'Authorization': `Bearer ${result.data.token}`,
            'Content-Type': 'application/json'
        };

        let bodyMsg =
            `<html><head></head><body><br />Dear Team,<br /> <br /> Booking has been approved and confirmed by Admin.<br />
             <span style=color:blue;>Booking Details</span> :
             <b>Booking No -</b> ${result.data.id}
             <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
             <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
             <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}        
             <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate), result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
             <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate), result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
             <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
             <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
             <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
            <span style=color:blue;>Products categories - </span> ${result.data.bookingDetails.productsDemostrated.map((product) => '<br />' + product)}<br />
            <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}        
            <br /> ${process.env.REACT_APP_ADMIN_PORTAL_URL}         
            <br /> <b>Thanks</b>
            FLUID Experience Centre Team        
            </body>
           </html>`
        bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')
        let sendmessageBody = `{
           "message": {
           "subject": "${result.data.id} - Booking Confirmed by Admin",
           "body": {
           "contentType": "Html",
           "content": "${bodyMsg}"
           }${addmore ? "," + addmore : ''}
           },
           "saveToSentItems": "true"
           }`
        const URL = `https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/sendMail`;
        axios.post(URL, sendmessageBody, { headers })
            .then(response => {

            }).catch(error => {
                new API().logServiceErrorToDatabase(error);
                console.log("error while sending mail", error);
            });
    }

    SendMeetingInvite(result) {
        const headers = {
            'Authorization': `Bearer ${result.data.token}`,
            'Content-Type': 'application/json'
        };

        let bodyMsg =
            `<html><head></head><body><br />Dear ${result.data.bookingDetails.bookingDetail.bookieName},<br /> <br /> Your booking has been confirmed.<br />
                 <span style=color:blue;>Booking Details</span> :
                 <b>Booking No -</b> ${result.data.id}
                 <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
                 <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
                 <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}
                 <b>Booking Start Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate), result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')} 
                 <b>Booking End Time -</b> ${moment.tz(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate), result.data.bookingDetails.bookingDetail.timezone).format('MMM Do YYYY, HH:mm A')}  
                 <b>Lunch -</b> ${result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No'}
                 <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
                 <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
                 <span style=color:blue;>Products categories - </span> ${result.data.bookingDetails.productsDemostrated.map((product) => '<br />' + product)}<br />
                 <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}
                 <br />
                 <b>Thanks</b>
                 FLUID Experience Centre Team
                </body>
               </html>`
        bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br>')

        let optionalUsers = result.data.adminEmails.map((adminMail) => `{
                  "emailAddress": {
                    "address":"${adminMail.trim()}"
                  },
                  "type": "optional"
                }`)

        let MeetingBody = `{
                    "subject": "Booking Confirmed #${result.data.id}",
                    "body": {
                      "contentType": "HTML",
                      "content": "${bodyMsg}"
                    },
                    "start": {
                      "dateTime": "${moment.utc(result.data.bookingDetails.bookingDetail.bookingDate).format('YYYY-MM-DD h:mm:ss A')}",
                      "timeZone": "UTC"    
                    },
                    "end": {
                      "dateTime": "${moment.utc(result.data.bookingDetails.bookingDetail.bookingDate).add(result.data.meetingHours, "hours").format('YYYY-MM-DD h:mm:ss A')}",
                      "timeZone": "UTC"     
                    },
                    "location":{
                        "displayName":"Fluid Experience Centre ${result.data.bookingDetails.bookingDetail.location}"                        
                    },
                    "attendees": [
                      {
                        "emailAddress": {
                          "address":"${result.data.bookingDetails.bookingDetail.bookieEmail}"        
                        },
                        "type": "required"
                      }${optionalUsers.length > 0 ? "," + optionalUsers : ''}
                    ],
                    "isOnlineMeeting": true,
                    "onlineMeetingProvider": "teamsForBusiness"
                  }`;

        const URL = 'https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/calendar/events';
        axios.post(URL, MeetingBody, { headers })
            .then(response => {

                let reminder = {
                    BookingId: result.data.bookingDetails.bookingDetail.id,
                    ConfirmationEmailSentToAdmins: true,
                    ConfirmationEmailSentToBookie: true
                };

                new API().updateBookingReminderAfterConfirm(reminder).then(res => {
                });

            }).catch(error => {
                new API().logServiceErrorToDatabase(error);
                console.log('Fialed to create meeting....', error);
            });

    }

    SendUpdatedMeetingInvite(result) {
        const headers = {
            'Authorization': `Bearer ${result.data.token}`,
            'Content-Type': 'application/json',
            'Prefer': 'outlook.timezone="India Standard Time"'
        };

        let bookingTime = this.state.IsSelectedDateChange ? `<b>Booking Time -</b><span style=background:yellow;> ${moment(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</span> <br /> <b>Booking End Time -</b><span style=background:yellow;> ${moment(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')}</span>` :
            `<b>Booking Time -</b> ${moment(moment.utc(result.data.bookingDetails.bookingDetail.bookingDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')} <br / > <b>Booking End Time -</b> ${moment(moment.utc(result.data.bookingDetails.bookingDetail.bookingEndDate).toDate()).local().format('MMMM Do YYYY, HH:mm A')} `

        let lunchRequired = this.state.IsLunchRequiredChange ? `<b>Lunch -</b><span style=background:yellow;>${this.getLunchValue(result)}</span>` :
            `<b>Lunch -</b> ${this.getLunchValue(result)}`

        let bodyMsg =
            `<html><head></head><body><br />Dear ${result.data.bookingDetails.bookingDetail.bookieName},<br /> <br /> Your booking has been updated and confirmed.<br />                 
                 <span style=color:blue;>Updated booking details</span>:
                 <b>Booking No -</b> ${result.data.id}
                 <b>Customer Name -</b> ${result.data.bookingDetails.bookingDetail.customerName}
                 <b>Customer Type -</b> ${result.data.bookingDetails.bookingDetail.customerTypeName}
                 <b>Location -</b> ${result.data.bookingDetails.bookingDetail.location}
                 ${bookingTime}
                 ${lunchRequired}
                 <b>Visiting Party Size -</b> ${result.data.bookingDetails.bookingDetail.visitingPartySize}
                 <b>Participant Size -</b> ${result.data.bookingDetails.bookingDetail.participantsSize} <br />
                 <span style=color:blue;>Products categories - </span> ${result.data.bookingDetails.productsDemostrated.map((product) => '<br />' + product)}<br />
                 <span style=color:blue;>Visiting delegation -</span> ${result.data.bookingDetails.customers.map((cutomer) => '<br />' + cutomer.customerName + '       (' + cutomer.customerRole + ')    , ' + cutomer.customerEmail)}
                 <br />
                 <b>Thanks</b>
                 FLUID Experience Centre Team
                </body>
               </html>`
        bodyMsg = bodyMsg.replace(/(?:\r\n|\r|\n)/g, '<br />')

        let optionalUsers = result.data.adminEmails.map((adminMail) => `{
                  "emailAddress": {
                    "address":"${adminMail.trim()}"
                  },
                  "type": "optional"
                }`)

        let MeetingBody = `{
                    "subject": "Booking updated and confirmed #${result.data.id}",
                    "body": {
                      "contentType": "HTML",
                      "content": "${bodyMsg}"
                    },
                    "start": {
                      "dateTime": "${moment(result.data.bookingDetails.bookingDetail.bookingDate).format('YYYY-MM-DD h:mm:ss A')}",
                      "timeZone": "India Standard Time"    
                    },
                    "end": {
                      "dateTime": "${moment(result.data.bookingDetails.bookingDetail.bookingDate).add(result.data.meetingHours, "hours").format('YYYY-MM-DD h:mm:ss A')}",
                      "timeZone": "India Standard Time"     
                    },
                    "location":{
                        "displayName":"Temporary"
                    },
                    "attendees": [
                      {
                        "emailAddress": {
                          "address":"${result.data.bookingDetails.bookingDetail.bookieEmail}"        
                        },
                        "type": "required"
                      }${optionalUsers.length > 0 ? "," + optionalUsers : ''}
                    ],
                    "isOnlineMeeting": true,
                    "onlineMeetingProvider": "teamsForBusiness"
                  }`;

        const URL = 'https://graph.microsoft.com/v1.0/users/fluidcentrebookingsystem@hcldwpdev.com/calendar/events';

        axios.post(URL, MeetingBody, { headers })
            .then(response => {

                let reminder = {
                    BookingId: result.data.bookingDetails.bookingDetail.id,
                    ConfirmationEmailSentToAdmins: true,
                    ConfirmationEmailSentToBookie: true
                };

                new API().updateBookingReminderAfterConfirm(reminder).then(res => {
                });
            }).catch(error => {
                new API().logServiceErrorToDatabase(error);
                console.log('Fialed to create meeting....', error);
            });
    }

    OnDateChange = (value) => {
        this.setState({ fields: { ...this.state.fields, selectedDate: value } });

        if (localStorage.getItem("isValidationRequired") == "true") {
            new API().IsValidBookingSlotForConfirm(moment(value).format('YYYY-MM-DD hh:mm:ss a'), this.state.selectedId, this.state.selectedDetails.bookingSlot).then(result => {
                this.setState({ isDateValid: result.data })
            });
        }
    }

    getLunchValue(result) {
        return result.data.bookingDetails.bookingDetail.lunchRequired ? 'Yes' : 'No';
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.fields.selectedDate !== this.state.fields.selectedDate) {
            this.setState({ IsSelectedDateChange: true })
        }
        if (prevState.lunchRequired !== this.state.lunchRequired) {
            this.setState({ IsLunchRequiredChange: true })
        }
    }

    getTranslations(key) {

        if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
            return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
        else
            return "";
    }

    render() {

        return (
            <div className="modal show" style={{ display: 'block', position: 'initial' }}>

                <Modal
                    show={this.props.isOpen}
                    onHide={this.props.onCloseModel}
                    className="cust-modal"
                >

                    <Modal.Header>
                        <h5 id="exampleModalLabel">{this.getTranslations("Admin_ConfirmPage_Heading")}</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.form.handleSubmit}>
                            <div className="form-wizard">
                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Name")}</label>
                                        <input type="text" name="bookieName" value={this.state.selectedDetails.bookieName} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Email")}</label>
                                        <input type="email" name="customerEmail" value={this.state.selectedDetails.bookieEmail} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_VisitingCustomer")}</label>
                                        <input type="text" name="customerName" value={this.state.selectedDetails.customerName} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Guests")}</label>
                                        <input type="text" name="customerGuest" disabled className="form-control" value={(this.state.selectedDetails.visitingPartySize + this.state.selectedDetails.participantsSize) + " Guests (" + this.state.selectedDetails.visitingPartySize + this.state.displayCustomerText + this.state.selectedDetails.participantsSize + " HCL)"} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Vertical")}</label>
                                        <input type="text" name="vertical" value={this.state.selectedDetails.verticalName} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_GEO")} </label>
                                        <input type="text" name="Region" disabled className="form-control" value={(this.state.selectedDetails.regionName + " - " + this.state.selectedDetails.subRegionName)} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin">
                                        <label> {this.getTranslations("Admin_ConfirmPage_VisitType")}</label>
                                        <input type="text" name="vertical" value={this.state.selectedDetails.visitType} disabled className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="col-md-6">
                                        <label> {this.getTranslations("Admin_ConfirmPage_Lunch")}</label>
                                        {this.props.selectedName == "confirm" ?

                                            <select disabled name="lunchRequired" className="form-select" value={this.state.lunchRequired} >
                                                <option value="Yes" id="Yes"> Yes</option>
                                                <option value="No" id="No"> No</option>
                                            </select>
                                            :
                                            <select value={this.state.lunchRequired} onChange={(e) => this.setState({ lunchRequired: e.target.value })} name="lunchRequired" className="form-control form-select">
                                                <option value="Yes" id="Yes"> Yes</option>
                                                <option value="No" id="No"> No</option>
                                            </select>
                                        }
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-md-6 mob-margin form-group position-relative">
                                        <label>{this.getTranslations("Admin_ConfirmPage_StartDate")}</label>
                                        <br />
                                        {this.props.selectedName == "confirm" ?

                                            <DatePicker dateFormat="MM/dd/yyyy HH:mm a" disabled
                                                name="selectedDate"
                                                selected={this.state.fields.selectedDate}
                                                onChange={(date) => this.OnDateChange(date)}
                                                className="form-control"
                                                showTimeSelect
                                                onChangeRaw={(e) => e.preventDefault()}
                                            />
                                            :
                                            <>
                                                <DatePicker dateFormat="Pp"
                                                    name="selectedDate"
                                                    selected={this.state.fields.selectedDate}
                                                    onChange={(date) => this.OnDateChange(date)}
                                                    excludeTimes={[setHours(setMinutes(new Date(), 0), 20),
                                                    setHours(setMinutes(new Date(), 30), 20),
                                                    setHours(setMinutes(new Date(), 0), 21),
                                                    setHours(setMinutes(new Date(), 30), 21),
                                                    setHours(setMinutes(new Date(), 0), 22),
                                                    setHours(setMinutes(new Date(), 30), 22),
                                                    setHours(setMinutes(new Date(), 0), 23),
                                                    setHours(setMinutes(new Date(), 30), 23),
                                                    setHours(setMinutes(new Date(), 0), 24),
                                                    setHours(setMinutes(new Date(), 30), 24),
                                                    setHours(setMinutes(new Date(), 0), 1),
                                                    setHours(setMinutes(new Date(), 30), 1),
                                                    setHours(setMinutes(new Date(), 0), 2),
                                                    setHours(setMinutes(new Date(), 30), 2),
                                                    setHours(setMinutes(new Date(), 0), 3),
                                                    setHours(setMinutes(new Date(), 30), 3),
                                                    setHours(setMinutes(new Date(), 0), 4),
                                                    setHours(setMinutes(new Date(), 30), 4),
                                                    setHours(setMinutes(new Date(), 0), 5),
                                                    setHours(setMinutes(new Date(), 30), 5),
                                                    setHours(setMinutes(new Date(), 0), 6),
                                                    setHours(setMinutes(new Date(), 30), 6),
                                                    setHours(setMinutes(new Date(), 0), 7),
                                                    setHours(setMinutes(new Date(), 30), 7)
                                                    ]}
                                                    className="form-control"
                                                    minDate={moment().toDate()}
                                                    filterTime={this.filterPassedTime}
                                                    showTimeSelect
                                                    onChangeRaw={(e) => e.preventDefault()}
                                                />
                                                <FontAwesomeIcon className="calendar-icon2" icon={faCalendarAlt} />

                                                {
                                                    !this.state.isDateValid ?
                                                        <div style={{ color: 'red' }}>Selected slot is not available. Try again</div> : ''
                                                }
                                            </>
                                        }

                                    </div>

                                    <div className="col-md-6 mob-margin form-group position-relative">
                                        <label>{this.getTranslations("Admin_ConfirmPage_EndDate")}</label>
                                        <br />
                                        {this.props.selectedName == "confirm" ?

                                            <DatePicker dateFormat="MM/dd/yyyy HH:mm a" disabled
                                                name="selectedDate"
                                                selected={this.state.fields.selectedEndDate}
                                                onChange={(date) => this.OnDateChange(date)}
                                                className="form-control"
                                                showTimeSelect
                                                onChangeRaw={(e) => e.preventDefault()}
                                            />
                                            :
                                            <>
                                                <DatePicker dateFormat="Pp"
                                                    name="selectedDate"
                                                    selected={this.state.fields.selectedEndDate}
                                                    onChange={(date) => this.OnDateChange(date)}
                                                    excludeTimes={[setHours(setMinutes(new Date(), 0), 20),
                                                    setHours(setMinutes(new Date(), 30), 20),
                                                    setHours(setMinutes(new Date(), 0), 21),
                                                    setHours(setMinutes(new Date(), 30), 21),
                                                    setHours(setMinutes(new Date(), 0), 22),
                                                    setHours(setMinutes(new Date(), 30), 22),
                                                    setHours(setMinutes(new Date(), 0), 23),
                                                    setHours(setMinutes(new Date(), 30), 23),
                                                    setHours(setMinutes(new Date(), 0), 24),
                                                    setHours(setMinutes(new Date(), 30), 24),
                                                    setHours(setMinutes(new Date(), 0), 1),
                                                    setHours(setMinutes(new Date(), 30), 1),
                                                    setHours(setMinutes(new Date(), 0), 2),
                                                    setHours(setMinutes(new Date(), 30), 2),
                                                    setHours(setMinutes(new Date(), 0), 3),
                                                    setHours(setMinutes(new Date(), 30), 3),
                                                    setHours(setMinutes(new Date(), 0), 4),
                                                    setHours(setMinutes(new Date(), 30), 4),
                                                    setHours(setMinutes(new Date(), 0), 5),
                                                    setHours(setMinutes(new Date(), 30), 5),
                                                    setHours(setMinutes(new Date(), 0), 6),
                                                    setHours(setMinutes(new Date(), 30), 6),
                                                    setHours(setMinutes(new Date(), 0), 7),
                                                    setHours(setMinutes(new Date(), 30), 7)
                                                    ]}
                                                    className="form-control"
                                                    minDate={moment().toDate()}
                                                    filterTime={this.filterPassedTime}
                                                    showTimeSelect
                                                    onChangeRaw={(e) => e.preventDefault()}
                                                />
                                                <FontAwesomeIcon className="calendar-icon2" icon={faCalendarAlt} />

                                                {
                                                    !this.state.isDateValid ?
                                                        <div style={{ color: 'red' }}>Selected slot is not available. Try again</div> : ''
                                                }
                                            </>
                                        }

                                    </div>



                                </div>


                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <button onClick={this.props.onCloseModel} type="button" className="btn outline-button btn-small" data-bs-dismiss="modal">{this.getTranslations("Admin_ConfirmPage_ButtonClose")}</button>
                                <button disabled={this.state.isButtonDisabled} className="btn fill-button btn-small" type="submit" >{this.getTranslations("Admin_ConfirmPage_ButtonConfirm")}</button> &nbsp;
                            </div>
                        </form>
                    </Modal.Body>


                </Modal></div>
        )
    }

}

export default ConfirmBooking;