import { Component } from "react";
import { faChevronLeft, faChevronRight, faQuestionCircle } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import 'moment-timezone'
import API from "../../Api";
import '../../Css/slider-number.css'
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactFormInputValidation from "react-form-input-validation";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { loginUserInfoAdmin } from "../../Redux/Actions/AdminAuthenticationActions";
import { adminCurrentPage } from "../../Redux/Actions/AdminCurrentPageAction";
import { saveEditBookingInfo } from "../../Redux/Actions/AdminEditBookingAction";
import WarningConsent from "../AdminDashboard/WarningConsent";

class EditPage2 extends Component {

  constructor(props) {
    super(props);
    let currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + this.props.minimumBookingTimeFromCurrentDateInMinutes)
    console.log()
    this.state = {
      customers: this.props.AdminEditBooking.bookingDetails.customersEdited,
      excludedTimes: [],
      timezone : this.props.AdminInitialData.locations.filter(p=>p.id == this.props.AdminEditBooking.bookingDetails.locationId)[0].timeZone,
      fields:
      {
        sizeOfVisitingParty: this.props.AdminEditBooking.bookingDetails.sizeOfVisitingParty,
        hclParticipants: this.props.AdminEditBooking.bookingDetails.hclParticipants,
        selectedTime: this.props.AdminEditBooking.bookingDetails.selectedTime
      },
      timeSlotId: this.props.AdminEditBooking.bookingDetails.timeSlotId,
      selectedDate: this.props.AdminEditBooking.bookingDetails.selectedDate ? this.selectTimeZoneDate(moment.utc(this.props.AdminEditBooking.bookingDetails.selectedDate).toDate(),this.props.AdminInitialData.locations.filter(p=>p.id == this.props.AdminEditBooking.bookingDetails.locationId)[0].timeZone) : currentDate,
      selectedEndDate: this.props.AdminEditBooking.bookingDetails.selectedEndDate ? this.selectTimeZoneDate(moment.utc(this.props.AdminEditBooking.bookingDetails.selectedEndDate).toDate(),this.props.AdminInitialData.locations.filter(p=>p.id == this.props.AdminEditBooking.bookingDetails.locationId)[0].timeZone) : currentDate,
      isDateValid: true,            
      isTimeSlotValid: true,
      timeSlotValidMessage: 'This field is required.',
      errors: {},
      isWarningOpen : false,
      index: 1,  //index which u want to display first
      direction: 'null', //direction of the carousel..u need to set it to either 'next' or 'prev' based on user click
      nextIcon: <span> <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></span>,
      prevIcon: <span><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon></span>
    };

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      sizeOfVisitingParty: "required",
      hclParticipants: "required",
      //timeSlotId: "required",
      selectedTime: "required",
    });
    this.form.onformsubmit = (fields) => {

      if (this.state.timeSlotId == "" || this.state.timeSlotId == undefined) {
        this.setState({ isTimeSlotValid: false })
        return;
      }

      if (Object.keys(this.state.errors).length === 0 && this.state.isDateValid && this.state.isTimeSlotValid) {

        if (localStorage.getItem("isValidationRequired") == "true") {
          new API().IsValidBookingSlot(moment(this.state.selectedDate).format('YYYY-MM-DD hh:mm:ss a'), this.state.timeSlotId).then(result => {
            if (result) {
              if (this.state.customers && this.state.customers.length > this.state.fields.sizeOfVisitingParty) {
                //handle if someone change and selected less size of visiting party. remove other items from the customer list        
                for (let i = this.state.customers.length; i > (this.state.customers.length - this.state.fields.sizeOfVisitingParty); i--) {
                  let bookingDetails = this.props.AdminEditBooking.bookingDetails;
                  bookingDetails.customers = bookingDetails.customers.slice(0, -1);
                  this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
                }
              }

              for (let i = 0; i < this.state.fields.sizeOfVisitingParty; i++) {
                //handle previous button on page3 and retain already filled value.
                let customer = {
                  id: this.state.customers[i] ? this.state.customers[i].id : '',
                  customerName: this.state.customers[i] ? this.state.customers[i].customerName : '',
                  customerEmail: this.state.customers[i] ? this.state.customers[i].customerEmail : '',
                  customerRole: this.state.customers[i] ? this.state.customers[i].customerRole : '',
                  isValidated: this.state.customers[i] ? this.state.customers[i].isValidated : false,
                  index: i
                };

                let bookingDetails = this.props.AdminEditBooking.bookingDetails;
                bookingDetails.customersEdited = bookingDetails.customers.concat(customer);
                this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
              }

              let bookingDetails = this.props.AdminEditBooking.bookingDetails;

              bookingDetails.sizeOfVisitingParty = this.state.fields.sizeOfVisitingParty;
              bookingDetails.hclParticipants = this.state.fields.hclParticipants;
              bookingDetails.timeSlotId = this.state.timeSlotId;
              bookingDetails.selectedDate = moment(this.state.selectedDate);
              bookingDetails.selectedTime = moment(this.state.selectedDate);
              bookingDetails.selectedEndDate = moment(this.state.selectedEndDate);
              bookingDetails.selectedDateTimeZone = moment.tz(moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format();
              bookingDetails.selectedEndDateTimeZone = moment.tz(moment(this.state.selectedEndDate).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format();
             
              this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });

              this.props.adminCurrentPage(3);
              this.props.sendData(3);
            }
            else {
              this.setState({ isDateValid: false });
            }
          });
        }
        else {
          if (this.state.customers && this.state.customers.length > this.state.fields.sizeOfVisitingParty) {
            //handle if someone change and selected less size of visiting party. remove other items from the customer list        
            for (let i = this.state.customers.length; i > (this.state.customers.length - this.state.fields.sizeOfVisitingParty); i--) {

              let bookingDetails = this.props.AdminEditBooking.bookingDetails;
              bookingDetails.customers = bookingDetails.customers.slice(0, -1);
              this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });

            }
          }

          let bookingDetails1 = this.props.AdminEditBooking.bookingDetails;
          bookingDetails1.customersEdited = []
          this.props.saveEditBookingInfo({ bookingDetails: bookingDetails1 });

          for (let i = 0; i < this.state.fields.sizeOfVisitingParty; i++) {
            //handle previous button on page3 and retain already filled value.
            let customer = {
              id: this.state.customers[i] ? this.state.customers[i].id : '',
              customerName: this.state.customers[i] ? this.state.customers[i].customerName : '',
              customerEmail: this.state.customers[i] ? this.state.customers[i].customerEmail : '',
              customerRole: this.state.customers[i] ? this.state.customers[i].customerRole : '',
              isValidated: this.state.customers[i] ? this.state.customers[i].isValidated : false,
              index: i
            };

            let bookingDetails = this.props.AdminEditBooking.bookingDetails;
            bookingDetails.customersEdited = bookingDetails.customersEdited.concat(customer);
            this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
          }

          let bookingDetails = this.props.AdminEditBooking.bookingDetails;

          bookingDetails.sizeOfVisitingParty = this.state.fields.sizeOfVisitingParty;
          bookingDetails.hclParticipants = this.state.fields.hclParticipants;
          bookingDetails.timeSlotId = this.state.timeSlotId;
          bookingDetails.selectedDate = moment(this.state.selectedDate);
          bookingDetails.selectedTime = moment(this.state.selectedDate);
          bookingDetails.selectedEndDate = moment(this.state.selectedEndDate);
          bookingDetails.selectedDateTimeZone = moment.tz(moment(this.state.selectedDate).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format();
          bookingDetails.selectedEndDateTimeZone = moment.tz(moment(this.state.selectedEndDate).format('YYYY-MM-DD HH:mm:ss'), this.state.timezone).format();
         
          this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });

          this.props.adminCurrentPage(3);
          this.props.sendData(3);
        }
      }
    }
    let message = ReactFormInputValidation.getMessages('en');
    message.required = 'This field is required.'
    ReactFormInputValidation.setMessages('en', message);
  }

  selectTimeZoneDate(date, timezone) {
    const dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DD HH:mm")
    const localZone = moment(dateWithoutZone).format("Z")
    const dateWithLocalZone = [dateWithoutZone, localZone].join("")
    return new Date(dateWithLocalZone);
}

  PreviousButtonClick = () => {
    let bookingDetails = this.props.AdminEditBooking.bookingDetails;

    bookingDetails.sizeOfVisitingParty = this.state.fields.sizeOfVisitingParty;
    bookingDetails.hclParticipants = this.state.fields.hclParticipants;
    bookingDetails.timeSlotId = this.state.timeSlotId;
    bookingDetails.selectedDate = moment(this.state.selectedDate);
    bookingDetails.selectedTime = moment(this.state.selectedDate);
    bookingDetails.selectedEndDate = moment(this.state.selectedEndDate);

    this.props.saveEditBookingInfo({ bookingDetails: bookingDetails });
    this.props.adminCurrentPage(1);
    this.props.sendData(1)
  }

  OnDateChange = (value) => {
    this.setState({ selectedDate: value });

    if (this.state.timeSlotId == 1) {
      let EndDate = new Date(value);
      EndDate.setHours(EndDate.getHours() + 2);
      this.setState({ selectedEndDate: EndDate });
    }
    else if (this.state.timeSlotId == 2) {
      let EndDate = new Date(value);
      EndDate.setHours(EndDate.getHours() + 4);
      this.setState({ selectedEndDate: EndDate });
    }
    else if (this.state.timeSlotId == 3) {
      let EndDate = new Date(value);
      EndDate.setHours(EndDate.getHours() + 7);
      this.setState({ selectedEndDate: EndDate });
    }


    if (localStorage.getItem("isValidationRequired") == "true") {
      new API().IsValidBookingSlot(moment(value).format("YYYY-MM-DD hh:mm:ss a"), this.state.timeSlotId).then(result => {
        this.setState({ isDateValid: result.data })
      });
    }
  }

  OnEndDateChange = (value) => {
    if (value < this.state.selectedDate) {
      this.setState({isWarningOpen : true})
    }
    else {
      this.setState({ selectedEndDate: value });
    }
  }

  onCloseWarningModel = (e) =>{
    this.setState({isWarningOpen : false})
}

  OnTimeSlotChange = (e) => {
    if (e.target.value != "") {

      if (e.target.value == 1) {
        let EndDate = new Date(this.state.selectedDate);
        EndDate.setHours(EndDate.getHours() + 2);
        this.setState({ selectedEndDate: EndDate });
      }
      else if (e.target.value == 2) {
        let EndDate = new Date(this.state.selectedDate);
        EndDate.setHours(EndDate.getHours() + 4);
        this.setState({ selectedEndDate: EndDate });
      }
      else if (e.target.value == 3) {
        let EndDate = new Date(this.state.selectedDate);
        EndDate.setHours(EndDate.getHours() + 7);
        this.setState({ selectedEndDate: EndDate });
      }

      this.setState({ timeSlotId: e.target.value, isTimeSlotValid: true, timeSlotValidMessage: '' })
    }
    else {
      this.setState({ timeSlotId: e.target.value, isTimeSlotValid: false, timeSlotValidMessage: 'This field is required.' })
    }
  }


  renderTooltip = (title) => (
    <Tooltip placement='right'>{title}</Tooltip>
  );

  getTranslations(key) {

    if (this.props.Translations.translations.filter(p => p.appKey == key)[0] != null)
      return this.props.Translations.translations.filter(p => p.appKey == key)[0].appValue;
    else
      return "";
  }


  render() {

    const filterPassedTime = (time) => {

      const currentDate = new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() <= selectedDate.getTime();
    };

    const { nextIcon, prevIcon } = this.state;
    return <><form onSubmit={this.form.handleSubmit}><fieldset className="wizard-fieldset show">
      <div className="col-md-12 right-section">
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label"> {this.getTranslations("User_BookingNextPage_VisitingDelegation")} </label>
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_VisitingDelegationTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
          <br />
          <div className={this.state.errors.sizeOfVisitingParty ? "stv-radio-buttons-wrapper participants is-invalid customslider" : "stv-radio-buttons-wrapper participants customslider"}>

            <Carousel interval={null} indicators={false} style={{ background: 'gray' }} prevIcon={prevIcon} nextIcon={nextIcon} nextLabel={""} prevLabel={""}>
              <Carousel.Item key="svm1">
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="1" id="sizestart" />
                <label htmlFor="size1"><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon><i className="fa-solid fa-chevron-left"></i></label>  */}
                <input checked={this.state.fields.sizeOfVisitingParty == 1 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="1" id="size1" />
                <label htmlFor="size1">1</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 2 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="2" id="size2" />
                <label htmlFor="size2">2</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 3 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="3" id="size3" />
                <label htmlFor="size3">3</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 4 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="4" id="size4" />
                <label htmlFor="size4">4</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 5 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="5" id="size5" />
                <label htmlFor="size5">5</label>
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="5" id="size5" />
                <label htmlFor="size5"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon><i className="fa-solid fa-chevron-right"></i></label> */}
              </Carousel.Item>
              <Carousel.Item key="svm2">
                <input checked={this.state.fields.sizeOfVisitingParty == 6 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="6" id="size6" />
                <label htmlFor="size6">6</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 7 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="7" id="size7" />
                <label htmlFor="size7">7</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 8 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="8" id="size8" />
                <label htmlFor="size8">8</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 9 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="9" id="size9" />
                <label htmlFor="size9">9</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 10 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="10" id="size10" />
                <label htmlFor="size10">10</label>
              </Carousel.Item>
              <Carousel.Item key="svm3">
                <input checked={this.state.fields.sizeOfVisitingParty == 11 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="11" id="size11" />
                <label htmlFor="size11">11</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 12 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="12" id="size12" />
                <label htmlFor="size12">12</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 13 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="13" id="size13" />
                <label htmlFor="size13">13</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 14 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="14" id="size14" />
                <label htmlFor="size14">14</label>
                <input checked={this.state.fields.sizeOfVisitingParty == 15 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="15" id="size15" />
                <label htmlFor="size15">15</label>

              </Carousel.Item>
            </Carousel>
            {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="sizeOfVisitingParty" value="15" id="size15" /> */}
            {/* <label htmlFor="size15"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon><i className="fa-solid fa-chevron-right"></i></label> */}
          </div>
          <label className="invalid-feedback">
            {this.state.errors.sizeOfVisitingParty ? this.state.errors.sizeOfVisitingParty : ""}
          </label>
        </div>


        <div className="mb-3">
          <label htmlFor="" className="form-label">{this.getTranslations("User_BookingNextPage_HostingParty")}</label>
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_HostingPartyTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
          <br />
          <div className={this.state.errors.hclParticipants ? "stv-radio-buttons-wrapper participants is-invalid customslider" : "stv-radio-buttons-wrapper participants customslider"}>
            <Carousel interval={null} indicators={false} style={{ background: 'gray' }} prevIcon={prevIcon} nextIcon={nextIcon} nextLabel={""} prevLabel={""}>
              <Carousel.Item key="ht1">
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="hclParticipants" value="1" id="partbuttonstart" />
            <label htmlFor="part1"><FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon><i className="fa-solid fa-chevron-left"></i></label> */}

                <input checked={this.state.fields.hclParticipants == 1 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="1" id="part1" />
                <label htmlFor="part1">1</label>

                <input checked={this.state.fields.hclParticipants == 2 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="2" id="part2" />
                <label htmlFor="part2">2</label>

                <input checked={this.state.fields.hclParticipants == 3 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="3" id="part3" />
                <label htmlFor="part3">3</label>

                <input checked={this.state.fields.hclParticipants == 4 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="4" id="part4" />
                <label htmlFor="part4">4</label>

                <input checked={this.state.fields.hclParticipants == 5 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="5" id="part5" />
                <label htmlFor="part5">5</label>
                {/* <input onChange={this.OnChangeHandler} type="radio" className="stv-radio-button" name="hclParticipants" value="10" id="part10" />
            <label htmlFor="part5"><FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon><i className="fa-solid fa-chevron-right"></i></label> */}

              </Carousel.Item>
              <Carousel.Item key="ht2">
                <input checked={this.state.fields.hclParticipants == 6 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="6" id="part6" />
                <label htmlFor="part6">6</label>

                <input checked={this.state.fields.hclParticipants == 7 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="7" id="part7" />
                <label htmlFor="part7">7</label>

                <input checked={this.state.fields.hclParticipants == 8 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="8" id="part8" />
                <label htmlFor="part8">8</label>

                <input checked={this.state.fields.hclParticipants == 9 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="9" id="part9" />
                <label htmlFor="part9">9</label>

                <input checked={this.state.fields.hclParticipants == 10 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="10" id="part10" />
                <label htmlFor="part10">10</label>

              </Carousel.Item>
              <Carousel.Item key="ht3">
                <input checked={this.state.fields.hclParticipants == 11 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="11" id="part11" />
                <label htmlFor="part11">11</label>

                <input checked={this.state.fields.hclParticipants == 12 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="12" id="part12" />
                <label htmlFor="part12">12</label>

                <input checked={this.state.fields.hclParticipants == 13 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="13" id="part13" />
                <label htmlFor="part13">13</label>

                <input checked={this.state.fields.hclParticipants == 14 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="14" id="part14" />
                <label htmlFor="part14">14</label>

                <input checked={this.state.fields.hclParticipants == 15 ? true : false} onChange={this.form.handleChangeEvent} onBlur={this.form.handleBlurEvent} type="radio" className="stv-radio-button" name="hclParticipants" value="15" id="part15" />
                <label htmlFor="part15">15</label>
              </Carousel.Item>
            </Carousel>

          </div>
          <label className="invalid-feedback">
            {this.state.errors.hclParticipants ? this.state.errors.hclParticipants : ""}
          </label>
        </div>


        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">{this.getTranslations("User_BookingNextPage_VisitDuration")}</label>
          <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_VisitDurationTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
          <br />
          <div className={!this.state.isTimeSlotValid ? "stv-radio-buttons-wrapper is-invalid" : "stv-radio-buttons-wrapper"}>

            {this.props.AdminInitialData.timeSlots.map(time => time.id == this.state.timeSlotId ?
              <>
                <input onChange={(e) => this.OnTimeSlotChange(e)}
                  onBlur={this.form.handleBlurEvent}
                  key={time.id + 'slot'} type="radio"
                  className="stv-radio-button en-nn"
                  name="timeSlotId"
                  value={time.id} id={'button' + time.id} checked />
                <label key={'slotlabel' + time.id} htmlFor={'button' + time.id}>  {time.slotType}  {'(' + time.startRange + '-' + time.endRange + ' hrs.)'} </label>
              </> :
              <>
                <input onChange={(e) => this.OnTimeSlotChange(e)}
                  onBlur={this.form.handleBlurEvent} key={time.id + 'slot'} type="radio"
                  className="stv-radio-button en-nn" name="timeSlotId"
                  value={time.id} id={'button' + time.id} />
                <label key={'slotlabel' + time.id} htmlFor={'button' + time.id}>  {time.slotType}  {' (' + time.startRange + '-' + time.endRange + ' hrs.)'} </label>
              </>
            )}

          </div>
          <label className={this.state.isTimeSlotValid ? "disable" : "errorMessage"}>
            {this.state.isTimeSlotValid ? "" : this.state.timeSlotValidMessage}
          </label>
        </div>

        <div className="mb-3 row" >

          <div className="col-md-6">

            <label htmlFor="" className="form-label">{this.getTranslations("User_BookingNextPage_StartDate")} </label>

            <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_StartDateTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
            <br />
            <div className="form-group position-relative">

              <DatePicker dateFormat="MM/dd/yyyy HH:mm a"
                name="selectedDate"
                selected={this.state.selectedDate}
                excludeTimes={[setHours(setMinutes(new Date(), 0), 20),
                setHours(setMinutes(new Date(), 30), 20),
                setHours(setMinutes(new Date(), 0), 21),
                setHours(setMinutes(new Date(), 30), 21),
                setHours(setMinutes(new Date(), 0), 22),
                setHours(setMinutes(new Date(), 30), 22),
                setHours(setMinutes(new Date(), 0), 23),
                setHours(setMinutes(new Date(), 30), 23),
                setHours(setMinutes(new Date(), 0), 24),
                setHours(setMinutes(new Date(), 30), 24),
                setHours(setMinutes(new Date(), 0), 1),
                setHours(setMinutes(new Date(), 30), 1),
                setHours(setMinutes(new Date(), 0), 2),
                setHours(setMinutes(new Date(), 30), 2),
                setHours(setMinutes(new Date(), 0), 3),
                setHours(setMinutes(new Date(), 30), 3),
                setHours(setMinutes(new Date(), 0), 4),
                setHours(setMinutes(new Date(), 30), 4),
                setHours(setMinutes(new Date(), 0), 5),
                setHours(setMinutes(new Date(), 30), 5),
                setHours(setMinutes(new Date(), 0), 6),
                setHours(setMinutes(new Date(), 30), 6),
                setHours(setMinutes(new Date(), 0), 7),
                setHours(setMinutes(new Date(), 30), 7)
                ]}
                onChange={this.OnDateChange}
                className="form-control"
                minDate={moment(new Date()).add(1, 'hour').toDate()}
                filterTime={filterPassedTime}
                showTimeSelect
                onChangeRaw={(e) => e.preventDefault()}
              />
              <FontAwesomeIcon className="calendar-icon" icon={faCalendarAlt} />


              {
                !this.state.isDateValid ?
                  <div style={{ color: 'red' }}>Selected slot is not available. Try again</div> : ''
              }



            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor="" className="form-label"> {this.getTranslations("User_BookingNextPage_EndDate")} </label>

            <OverlayTrigger overlay={this.renderTooltip(this.getTranslations("User_BookingNextPage_EndDateTooltip"))}><span> <FontAwesomeIcon icon={faQuestionCircle} size={'1x'} /> </span></OverlayTrigger>
            <br />
            <div className="form-group position-relative">

              <DatePicker dateFormat="MM/dd/yyyy HH:mm a"
                name="selectedEndDate"
                selected={this.state.selectedEndDate}
                onChange={this.OnEndDateChange}
                className="form-control"
                minDate={moment(new Date()).add(1, 'hour').toDate()}
                filterTime={filterPassedTime}
                showTimeSelect
                onChangeRaw={(e) => e.preventDefault()}
              />
              <FontAwesomeIcon className="calendar-icon" icon={faCalendarAlt} />


              {
                !this.state.isDateValid ?
                  <div style={{ color: 'red' }}>Selected slot is not available. Try again</div> : ''
              }



            </div>
          </div>
          <div>{this.getTranslations("User_BookingNextPage_DateNote")}</div>



          <label className="invalid-feedback">
            {this.state.errors.selectedDate ? this.state.errors.selectedDate : ""}

          </label>
        </div>

      </div>


      <div className="form-group clearfix row btn-fix-bottom">
        <div className="text-center main-btn-fix">
          <button onClick={this.PreviousButtonClick} className="form-wizard-previous-btn ">Previous</button>
          <input type="submit" value="Continue" className="form-wizard-next-btn " />
        </div>
      </div>
    </fieldset>
    </form>
      {
        this.state.isWarningOpen ?
          <WarningConsent infoMessage="End date should be greater than start date." isWarningOpen={this.state.isWarningOpen} onCloseModel={this.onCloseWarningModel} />
          : ""
      }
    </>

  }
}

const mapStateToProps = (state) => ({
  Translations: state.Translations,
  AdminAuthentication: state.AdminAuthentication,
  AdminEditBooking: state.AdminEditBooking,
  AdminCurrentPage: state.AdminCurrentPage,
  AdminInitialData: state.AdminInitialData
})

export default connect(mapStateToProps, { loginUserInfoAdmin, adminCurrentPage, saveEditBookingInfo })(EditPage2);
